(function () {
  'use strict';

  var countryNameFallback = {
    russia: 'russianfederation',
    'czechrep.': 'czech',
    czechia: 'czech',
    usa: 'unitedstates',
    unitedstatesofamerica: 'unitedstates',
    latviaflag: 'latvia',
    'u.k.': 'unitedkingdom',
  };

  var availableCountryFlags = [
    'australia',
    'austria',
    'belarus',
    'belgium',
    'bosniaandherzegovina',
    'bulgaria',
    'canada',
    'china',
    'croatia',
    'czech',
    'czechrep.',
    'czechia',
    'czechrepublic',
    'denmark',
    'estonia',
    'finland',
    'france',
    'germany',
    'greece',
    'hongkong',
    'hungary',
    'iceland',
    'international',
    'ireland',
    'israel',
    'italy',
    'japan',
    'kazakhstan',
    'latvia',
    'lithuania',
    'luxembourg',
    'mexico',
    'mongolia',
    'netherlands',
    'newzealand',
    'norvey',
    'norway',
    'poland',
    'romania',
    'russia',
    'russianfederation',
    'serbia',
    'slovakia',
    'slovenia',
    'southafrica',
    'spain',
    'sweden',
    'switzerland',
    'turkey',
    'ukraine',
    'unitedarabemirates',
    'unitedkingdom',
    'u.k.',
    'unitedstates',
  ];

  function getCountryFlagImageUrl(countryName, type) {
    type = type || 'club';

    if (countryName.indexOf('.jpg') !== -1) {
      return '/img/large-flags/' + countryName;
    }

    if (availableCountryFlags.indexOf(countryName) === -1) {
      return '/img/phimg-' + type + '.jpg';
    }

    if (countryNameFallback[countryName]) {
      countryName = countryNameFallback[countryName];
    }
    return '/img/large-flags/' + countryName + '.jpg';
  }

  function getLeagueImageUrl(baseUrl, url) {
    if (url.indexOf('leagues/') === 0 || url.indexOf('logos/') === 0) {
      return baseUrl + '/' + url;
    }

    return baseUrl + '/img/' + url;
  }

  function scImageFilter(IMAGE_END_POINT) {
    return function (input, type) {
      var result = '/img/phimg-player.jpg';

      switch (type) {
        case 'country':
          input = input.toLowerCase().replace(/\s/g, '');
          result = getCountryFlagImageUrl(input, 'country');
          break;
        case 'player':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'staff':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'agent':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'league':
          result = input ? getLeagueImageUrl(IMAGE_END_POINT, input) : '/img/phimg-club.jpg';
          break;
        case 'team':
        case 'nat_team':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'agency':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'company':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'union':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'arena':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-country.jpg';
          break;
      }

      return result;
    };
  }

  angular.module('app.common').filter('scImage', scImageFilter);
})();
